<script setup lang="ts">
import { route } from 'ziggy-js';
import { useForm, usePage } from '@inertiajs/vue3';
import { FormOverlay, InputError, SGButton, SGCheckBox, SGInputText } from '@/Components/Form';
import { AnchorLink } from '@/Components/Frontend/Navigation';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import { IconEmail, IconErrorWarning, IconPassword } from '@/assets/icons/index';

defineProps({
	canResetPassword: {
		type: Boolean,
		default: false,
	},
	canRegister: {
		type: Boolean,
		default: usePage<any>()?.props?.canRegister,
	},
	canSso: {
		type: Boolean,
		default: usePage<any>()?.props?.feature_flags?.sso ?? false,
	},
	status: {
		type: String,
		default: null,
	},
});

const form = useForm({
	email: '',
	password: '',
	remember: false,
});

const params = new URLSearchParams(window.location.search);
const feedbackMsg = params.get('feedbackMsg') ?? undefined;
const feedbackType = params.get('feedbackType') ?? undefined;

const submit = (loginRoute: string) => {
	form
		.transform((data) => ({
			...data,
			remember: form.remember ? 'on' : '',
		}))
		.post(loginRoute, {
			onFinish: () => form.reset('password'),
		});
};
</script>

<template>
	<AuthLayout :title="$t('Log in')" :feedback-msg="feedbackMsg" :feedback-type="feedbackType">
		<div class="dark:text-white w-full md:w-[400px] lg:w-[500px] h-full">
			<div class="w-full h-full flex flex-col justify-center relative">
				<!--<div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>-->

				<div class="mb-14">
					<h1 class="text-primary text-j44-medium mb-2">
						{{ $t('Welcome back!') }}
					</h1>
					<span class="text-sg-dark-grape-100 text-j18-regular">{{
						$t('Enter your email and password to login.')
					}}</span>
				</div>

				<FormOverlay :form="form" :back-route="''" has-custom-submit-button @submitted="submit(route('login'))">
					<SGInputText
						id="email"
						v-model="form.email"
						type="email"
						placeholder="Email"
						placeholder-style="floating"
						required
						hide-required
						autofocus
						name="email"
						:icon="IconEmail"
						class="block w-full"
						:has-error="!!form.errors.email"
					/>

					<SGInputText
						id="password"
						v-model="form.password"
						placeholder="Password"
						placeholder-style="floating"
						autocomplete="current-password"
						password-toggle
						required
						hide-required
						autofocus
						name="password"
						class="my-6 block w-full"
						:icon="IconPassword"
						:has-error="!!form.errors.password"
					/>

					<div class="w-full flex justify-between items-center">
						<label class="flex items-center">
							<SGCheckBox v-model="form.remember" name="remember" />
							<span class="ms-2 text-sm text-sg-grey-300">{{ $t('Remember me') }}</span>
						</label>
						<AnchorLink
							v-if="canResetPassword"
							:inertia="true"
							:href="route('password.request')"
							class="text-sg-grey-300 text-sm text-decoration-none"
							:label="$t('Forgot password?')"
						>
							{{ $t('Forgot password?') }}
						</AnchorLink>
					</div>
					<div v-if="form.errors.email" class="flex items-center space-x-2 mt-8">
						<IconErrorWarning class="w-5 h-5 mt-2" />
						<InputError class="mt-2" :message="form.errors.email" />
					</div>
					<SGButton is-cta class="w-full mt-10" type="submit"> {{ $t('Login') }} </SGButton>
				</FormOverlay>
				<!--            <div class="mt-4">
                <AnchorLink v-if="canRegister" :inertia="true" :href="route('register')" :label="$t('Register')">
                    {{ $t("I don't have an account") }}
                </AnchorLink>
            </div>
            <div v-if="canSso" class="mt-10">
                <GoogleButton :label="$t('auth.login_with_social', {provider: 'Google'})" />
            </div>-->
			</div>
		</div>
	</AuthLayout>
</template>
